<template>
	<div class="detail-movie" v-loading="loading" element-loading-background="rgb(19, 21, 48)">
		<search-bar />

		<div v-if="type === null" style="text-align: center;width: 100%;min-height: 250px">
			<img :src="warnImage" alt="" style="margin-top: 230px;width: 400px;height: 250px;margin: 230px auto auto auto">
			<div style="font-size: 15px;
font-family: PingFang SC;
font-weight: 500;
line-height: 21px;
color: #797A8E;">{{warnMessage}}</div>
		</div>
		<div v-if="type !== null" class="detail-img" v-show="!loading">
			<div
				class="detail-img-left"
				:style="{
					width: 'calc(100% ' + type == 'tv' ? '- 400px' : '- 0px)',
					height: type == 'tv' ? tvHeight + 'px' : 'auto',
				}"
			>
				<img
					:src="getImg(detail.bigPoster, 1100, type == 'tv' ? 675 : 490)"
					class="background"
					alt=""
					type="bigPoster"
					v-show="!isPlay"
					:onerror="img[type]"
				/>


				<img :src="bigPlayImg" alt="" class="play" @click="goPlay" v-show="!isPlay" />
				<div id="dplayer" v-show="isPlay" :style="videoStyle"></div>
			</div>
			<div class="detail-img-right" v-if="type == 'tv'" :style="{ height: tvHeight + 'px' }">
				<div class="tv-box">
					<div class="tv-box-title" style="padding:0;">{{ detail.title }}</div>
					<div class="tv-item-count" style="padding:0;">
						<div class="tv-item-count-left">
							总{{ detail.tv && detail.tv.playInfo && detail.tv.playInfo.length }}季
							<span>·</span>
							共{{ detail.tvLength }}集
						</div>
						<div class="tv-item-count-right">
							<span>自动播放</span>
							<el-switch v-model="isAuto" active-color="#FF3465" inactive-color=""> </el-switch>
						</div>
					</div>
					<div
						class="tv-item"
						v-for="(item, index) in detail.tv.playInfo"
						:key="index + item.title"
						style="margin:0;"
					>
						<div class="tv-item-title" @click="clickTvTitle(index)">
							<div class="tv-item-title-left">
								<span
									class="tv-item-title-1"
									:style="{
										color: item.isShow ? 'rgba(255, 52, 101, 1)' : '#b6b7c3',
									}"
								>{{ item.title }}</span
								>
								<span class="tv-item-title-2">全{{ item.episode.length }}集</span>
							</div>
							<div class="tv-item-title-right">
								<img :src="item.isShow ? openIcon : closeIcon" alt="" />
							</div>
						</div>
						<div class="tv-item-detail" v-show="item.isShow">
							<div
								class="tv-item-detail-item"
								:class="{
									'tv-item-detail-item-selected': child.episodeId === detail.tvSelected,
								}"
								v-for="(child, childIndex) in item.episode"
								@click="selectTv(child, index)"
								:key="childIndex + child.number"
							>
								{{ child.number }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="type !== null" class="movie-detail" v-show="!loading">
			<div class="moive-detail-left">
				<div class="moive-detail-content">
					<div class="moive-detail-left-left">
												<img :src="getImg(detail.poster, '160', '230')" alt="" class="moive-detiil-left-left-thmub" />
						<div class="moive-detail-left-left-button">
							<img :src="detail.giveUp ? goodIcon : goodDisabledIcon" alt="" @click="clickGood()" />
							<img
								:src="detail.giveDown ? nogoodIcon : nogoodDisabledIcon"
								alt=""
								@click="clickUnGood()"
							/>
						</div>
					</div>
					<div class="moive-detail-left-right">
						<div class="moive-detail-left-right-title">
							<span
								style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
							>
								<span>{{ detail.title }}</span>
								<span class="create-button" @click="createHall">创建影厅</span>
								<span class="join-hall" style="margin-left: 20px" v-if="hallInfo.count > 0" @click="goHall">
									<img :src="hallImage" alt="">
									<span style="margin-left: 8px">加入放映中影厅</span>
								</span>
							</span>
							<span class="moive-detail-left-right-title-rating">
								<span>{{ detail.rating.toFixed(1) }}</span> /10
							</span>
						</div>
						<div class="moive-detail-left-right-tags">
							<div>{{ detail.year }}</div>
							<div v-for="(item, index) in detail.tags.slice(0, 4)" :key="index">
								{{ item.tagName }}
							</div>
						</div>
						<div class="moive-detail-left-right-text" v-show="detail.directors.length">
							<label>导演</label>
							<div class="moive-detail-left-right-text-content">
								<div v-for="(item, index) in detail.directors.slice(0, 2)" :key="index">
									<span v-show="index != 0" style="margin-left: 6px; margin-right: 4px">/</span>
									{{ item.name }}
								</div>
							</div>
						</div>
						<div class="moive-detail-left-right-text" v-show="detail.actors.length">
							<label>主演</label>
							<div class="moive-detail-left-right-text-content">
								<div v-for="(item, index) in detail.actors.slice(0, 3)" :key="index">
									<span v-show="index != 0" style="margin-left: 6px; margin-right: 4px">/</span>
									{{ item.name }}
								</div>
							</div>
						</div>
						<div class="moive-detail-left-right-text" v-show="detail.intro">
							<label>简介</label>
							<div
								class="moive-detail-left-right-text-content"
								ref="intro"
								:class="{ lineThree: introShow }"
							>
								{{ detail.intro }}
							</div>
						</div>
						<span class="intro-button" @click="introSwitch" v-show="introShowButton">{{
							introShow ? "展开" : "收起"
						}}</span>
					</div>
				</div>

				<div v-if="!commentMaintain" class="moive-detail-comment">
					<div class="moive-detail-comment-avatar">
						<img
							:src="isLogin ? getImg(userData.userInfo.avatar, 60, 60) : avatarImage"
							alt=""
						/>
					</div>
					<div class="moive-detail-comment-text">
						<el-input
							style="width: 100%"
							type="textarea"
							:rows="2"
							v-model="commentValue"
							placeholder="发表公开评论..."
							maxlength="500"
						>
						</el-input>
						<div class="moive-detail-comment-button">
							<div class="moive-detail-comment-button-text">{{ commentValue.length }}字</div>
							<div class="moive-detail-comment-button-button" @click="setComment">
								发表
							</div>
						</div>
					</div>
				</div >
				<div v-if="!commentMaintain" class="moive-detail-comment-list">
					<div class="moive-detail-comment-list-title">
						<div class="moive-detail-comment-list-title-left">
							<img :src="titleIcon" alt="" />
							<span style="margin-left: 10px">评论（{{ commentLength }}）</span>
						</div>
						<div class="moive-detail-comment-list-title-right">
									<span @click="commentType = 2" :style="{ color: commentType == 2 ? '#ff3465' : '#797a8e' }">按热度</span>
							<span
								style="
                  height: 18px;
                  width: 2px;
                  background: #797a8e;
                  display: inline-block;
                  margin: 0 15px;
                "
							></span>

                <span @click="commentType = 1" :style="{ color: commentType == 1 ? '#ff3465' : '#797a8e' }">按时间</span>
						</div>
					</div>

					<div class="moive-detail-comment-list-content" v-show="commentList.length">
						<div
							class="moive-detail-comment-list-content-item"
							v-for="(item, index) in commentList"
							:key="index"
						>
							<div class="moive-detail-comment-list-content-item-avatar">
								<!-- <img :src="getImg(item.userInfo.avatar, 60, 60)" alt="" /> -->
								<lazy-pic :array="[item.userInfo.avatar, 60, 60]" :type="'avatar'" :isWrapper="'a'" />
							</div>
							<div class="moive-detail-comment-list-content-item-content">
								<div class="moive-detail-comment-list-content-item-content-1">
									<div class="moive-detail-comment-list-content-item-content-1-name">
										{{ item.userInfo.nickname }}
									</div>
									<div
										class="moive-detail-comment-list-content-item-content-1-top"
										v-show="item.comment.top"
									>
										置顶
									</div>
									<div class="moive-detail-comment-list-content-item-content-1-date">
										{{ item.comment.createdAt }}
									</div>
								</div>
								<div class="moive-detail-comment-list-content-item-content-2">
									{{ item.comment.comment }}
								</div>
								<div class="moive-detail-comment-list-content-item-content-3">
									<div class="moive-detail-comment-list-content-item-content-3-left">
										<div
											class="moive-detail-comment-list-content-item-content-3-left-good"
											@click="goodComment(item.comment)"
										>
											<img :src="item.comment.liked===1 ? goodIcon : goodDisabledIcon" />
											<span>点赞({{ item.comment.likeNum }})</span>
										</div>
										<div
											class="moive-detail-comment-list-content-item-content-3-left-submit"
											@click="item.isShowSubmit = !item.isShowSubmit"
										>
											回复
										</div>
									</div>
									<div
										class="moive-detail-comment-list-content-item-content-3-right"
										v-if="item.userInfo.userId === userData.userInfo.userId"
									>
										<el-dropdown @visible-change="(e) => clickMore(e, item.comment.commentId)">
											<img
												:src="item.comment.commentId == userMore ? moreIcon : more2Icon"
												style="cursor: pointer"
											/>
											<el-dropdown-menu slot="dropdown">
												<!-- <el-dropdown-item >
													<div @click="setCommentTop(item.comment)">设为置顶</div>
												</el-dropdown-item> -->
												<el-dropdown-item>
													<div @click="deleteComment(item.comment)">删除</div>
												</el-dropdown-item>
											</el-dropdown-menu>
										</el-dropdown>
									</div>
								</div>
								<div
									class="moive-detail-comment-list-content-item-content-4"
									v-show="item.isShowSubmit"
								>
									<el-input
										style="width: 100%"
										type="textarea"
										:rows="2"
										v-model="item.submitValue"
										placeholder="回复公开评论..."
										maxlength="500"
									>
									</el-input>
									<div
										class="moive-detail-comment-list-content-item-content-4-button"
										@click="submitByUser(item)"
									>
										回复
									</div>
								</div>
								<div
									class="moive-detail-comment-list-content-item-content-5"
									v-show="item.comment.hotChildren && item.comment.hotChildren.length"
								>
									<div
										class="moive-detail-comment-list-content-item-content-5-item"
										v-for="(child, childIndex) in item.comment.hotChildren"
										:key="child +'-'+ childIndex"
									>
										<div class="moive-detail-comment-list-content-item-content-5-item-data">
											<span
												class="moive-detail-comment-list-content-item-content-5-item-data-formname"
											>
												{{ child.userInfo.nickname }}
											</span>
											<span
												v-show="child.toUserInfo && child.toUserInfo.nickname"
												style="margin: 0 5px; flex-shrink: 0"
												class="moive-detail-comment-list-content-item-content-5-item-data-text"
											>回复</span
											>
											<span
												v-show="child.toUserInfo && child.toUserInfo.nickname"
												class="moive-detail-comment-list-content-item-content-5-item-data-formname"
											>
												{{ child.toUserInfo && child.toUserInfo.nickname }}
											</span>

											<span
												class="moive-detail-comment-list-content-item-content-5-item-data-formname"
											>
												：
											</span>
											<span
												class="moive-detail-comment-list-content-item-content-5-item-data-text"
												style="
                          white-space: normal;
                          word-break: break-all;
                          word-wrap: break-word;
                        "
											>
												{{ child.comment.comment }}
											</span>
										</div>
										<div class="moive-detail-comment-list-content-item-content-5-item-button">
											<div
												class="moive-detail-comment-list-content-item-content-5-item-button-left"
											>
												<div
													class="moive-detail-comment-list-content-item-content-5-item-button-date"
												>
													{{ child.comment.createdAt }}
												</div>
												<div
													class="moive-detail-comment-list-content-item-content-5-item-button-good"
													@click="goodComment(child.comment)"
													:style="{
														color: child.comment.liked
															? '#ff3465'
															: 'rgba(121, 122, 142, 0.5)',
													}"
												>
													点赞({{ child.comment.likeNum }})
												</div>
												<div
													class="moive-detail-comment-list-content-item-content-5-item-button-button"
													@click="child.isShowSubmit = !child.isShowSubmit"
												>
													回复
												</div>
											</div>
											<div
												class="moive-detail-comment-list-content-item-content-5-item-button-right"
											>
												<div
													class="moive-detail-comment-list-content-item-content-3-right"
													v-if="child.userInfo.userId === userData.userInfo.userId"
												>
													<el-dropdown
														@visible-change="(e) => clickMore(e, child.comment.commentId)"
													>
														<img
															:src="
																child.comment.commentId == userMore
																	? moreIcon
																	: more2Icon
															"
															style="cursor: pointer"
														/>
														<el-dropdown-menu slot="dropdown">
															<!-- <el-dropdown-item >
													<div @click="setCommentTop(item.comment)">设为置顶</div>
												</el-dropdown-item> -->
															<el-dropdown-item>
																<div @click="deleteComment(child.comment)">
																	删除
																</div>
															</el-dropdown-item>
														</el-dropdown-menu>
													</el-dropdown>
												</div>
											</div>
										</div>
										<div
											class="moive-detail-comment-list-content-item-content-4"
											v-show="child.isShowSubmit"
										>
											<el-input
												style="width: 100%"
												type="textarea"
												:rows="2"
												v-model="child.submitValue"
												placeholder="回复公开评论..."
												maxlength="500"
											>
											</el-input>
											<div
												class="moive-detail-comment-list-content-item-content-4-button"
												@click="submitByComment(child, item)"
											>
												回复
											</div>
										</div>
									</div>
									<span
										v-show="item.comment.children.length > 3 && !item.comment.showChildren"
										class="show-children-button"
										@click="item.comment.showChildren = true"
									>
										共有{{ item.comment.children.length }}条回复
									</span>

									<!-- <div v-if="item.comment.showChildren" class="show-children-line"></div> -->
									<div
										class="moive-detail-comment-list-content-item-content-5-item"
										v-for="(child, childIndex) in item.comment.children
											.filter(
												(b) =>
													!item.comment.hotChildren
														.map((i) => i.comment.commentId)
														.includes(b.comment.commentId)
											)
											.slice(item.comment.start, item.comment.end)"
										:key="childIndex"
									>
										<div
											class="moive-detail-comment-list-content-item-content-5-item-data"
											v-show="item.comment.showChildren"
										>
											<span
												class="moive-detail-comment-list-content-item-content-5-item-data-formname"
											>
												{{ child.userInfo.nickname }}
											</span>
											<span
												v-show="child.toUserInfo && child.toUserInfo.nickname"
												style="margin: 0 5px"
												class="moive-detail-comment-list-content-item-content-5-item-data-text"
											>回复</span
											>
											<span
												v-show="child.toUserInfo && child.toUserInfo.nickname"
												class="moive-detail-comment-list-content-item-content-5-item-data-formname"
											>
												{{ child.toUserInfo && child.toUserInfo.nickname }}
											</span>

											<span
												class="moive-detail-comment-list-content-item-content-5-item-data-formname"
											>
												：
											</span>
											<span
												class="moive-detail-comment-list-content-item-content-5-item-data-text"
											>
												{{ child.comment.comment }}
											</span>
										</div>
										<div
											class="moive-detail-comment-list-content-item-content-5-item-button"
											v-show="item.comment.showChildren"
										>
											<div
												class="moive-detail-comment-list-content-item-content-5-item-button-left"
											>
												<div
													class="moive-detail-comment-list-content-item-content-5-item-button-date"
												>
													{{ child.comment.createdAt }}
												</div>
												<div
													class="moive-detail-comment-list-content-item-content-5-item-button-good"
													@click="child.comment.liked ? null : goodComment(child.comment)"
													:style="{
														color: child.comment.liked
															? '#ff3465'
															: 'rgba(121, 122, 142, 0.5)',
													}"
												>
													点赞({{ child.comment.likeNum }})
												</div>
												<div
													class="moive-detail-comment-list-content-item-content-5-item-button-button"
													@click="child.isShowSubmit = !child.isShowSubmit"
												>
													回复
												</div>
											</div>
											<div
												class="moive-detail-comment-list-content-item-content-5-item-button-right"
											>
												<div
													class="moive-detail-comment-list-content-item-content-3-right"
													v-if="child.userInfo.userId === userData.userInfo.userId"
												>
													<el-dropdown
														@visible-change="(e) => clickMore(e, child.comment.commentId)"
													>
														<img
															:src="
																child.comment.commentId == userMore
																	? moreIcon
																	: more2Icon
															"
															style="cursor: pointer"
														/>
														<el-dropdown-menu slot="dropdown">
															<!-- <el-dropdown-item >
													<div @click="setCommentTop(item.comment)">设为置顶</div>
												</el-dropdown-item> -->
															<el-dropdown-item>
																<div @click="deleteComment(child.comment)">
																	删除
																</div>
															</el-dropdown-item>
														</el-dropdown-menu>
													</el-dropdown>
												</div>
											</div>
										</div>
										<div
											class="moive-detail-comment-list-content-item-content-4"
											v-show="child.isShowSubmit && item.comment.showChildren"
										>
											<el-input
												style="width: 100%"
												type="textarea"
												:rows="2"
												v-model="child.submitValue"
												placeholder="回复公开评论..."
												maxlength="500"
											>
											</el-input>
											<div
												class="moive-detail-comment-list-content-item-content-4-button"
												@click="submitByComment(child, item)"
											>
												回复
											</div>
										</div>
									</div>
									<page-select
										v-show="item.comment.showChildren"
										:pagination="{
											pageSize: 10,
											currentPage: item.comment.currentPage,
											totalPage: Math.ceil(item.comment.children.length / 10),
										}"
										@pageChange="
											(e) => {
												item.comment.end = e * 10
												item.comment.start = item.comment.end - 10
											}
										"
									></page-select>
									<span
										v-show="item.comment.children.length > 3 && item.comment.showChildren"
										class="show-children-button"
										@click="item.comment.showChildren = false"
									>
										收起
									</span>
								</div>
							</div>
						</div>
					</div>

					<div class="no-work" v-show="!commentList.length">
						<img :src="noworkIcon" alt="" />
						<span>暂无评论</span>
					</div>

					<page-select
						v-show="commentList.length"
						:pagination="pagination"
						@pageChange="pageChange"
					></page-select>
				</div>
				<div v-if="commentMaintain">

					<div style="text-align: center;width: 100%;min-height: 250px">
						<img :src="warnImage" alt="" style="margin-top: 50px;width: 400px;height: 250px;margin: 230px auto auto auto">
						<div style="font-size: 15px;
font-family: PingFang SC;
font-weight: 500;
line-height: 21px;
color: #797A8E;">{{commentMaintainMsg}}</div>
					</div>
				</div>
			</div>
			<div class="moive-detail-right">
				<div class="moive-detail-right-button">
					<div @click="isInPlayList ? deletePlayList() : setPlayList()">
						<img :src="buttonIcon1" alt="" />
						<span> {{ isInPlayList ? "移除看单" : "加入看单" }} </span>
					</div>
					<div>
						<img :src="buttonIcon2" alt="" @click="share()" />
						<span>分享</span>
					</div>
					<div>
						<img :src="buttonIcon3" alt="" @click="showBack" />
						<span>反馈</span>
					</div>
				</div>
				<div class="moive-detail-right-rec">
					<div class="moive-detail-right-rec-title">
						<img :src="recIcon" alt="" />
						<span>推荐观看</span>
					</div>
					<div
						v-for="(item, index) in recList"
						:key="index"
						class="moive-detail-right-rec-item"
					>
						<div class="moive-detail-right-rec-item-img"  @click="goLocalDetail(item.videoId)">
							<!-- <img
								:src="getImg(item.bigPoster, '320', '180')"
								alt=""
								@click="goLocalDetail(item.videoId)"
							/> -->
							<lazy-pic
								:array="[item.bigPoster, '320', '180']"
								:type="'bigPoster'"
								:isWrapper="'a'"
							/>
						</div>
						<div class="moive-detail-right-rec-item-text">
							<div class="moive-detail-right-rec-item-text-tags">
								<span v-for="(tag, tagIndex) in item.tags.slice(0, 3)" :key="tagIndex" style="cursor: none">
									<span v-show="tagIndex !== 0">·</span>
									<span class="moive-detail-right-rec-item-text-tags-main">{{ tag.tagName }}</span>
								</span>
							</div>
							<div class="moive-detail-right-rec-item-text-title" @click="goLocalDetail(item.videoId)">
								{{ item.title }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<el-dialog

				:visible="backShow"
				class="login-style"
				width="500px"
				:append-to-body="true"
				:close-on-click-modal="true"
				:show-close="false"

			>
				<span slot="title" >
					<span>反馈</span>
					<i class="el-icon-close" style="float:right;cursor: pointer" @click="backShow = false;"></i>
				</span>
				<div v-loading="backLoading" class="back-box-wrapper">
					<div class="back-box">
						<div
							class="back-box-item"
							:class="{ 'back-box-item-selected': backValue === item }"
							@click="backValue = item"
							v-for="(item, index) in backList"
							:key="index"
						>
							{{ item }}
						</div>
					</div>

					<div class="back-box-button" @click="submitBack">提交反馈</div>
				</div>
			</el-dialog>
			<el-dialog :visible="endShow" width="30%">
				<span>电影放映已结束。</span>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="endShow = false">确 定</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import searchBar from "./search-bar"
	import DPlayer from "dplayer"
	import pageSelect from "./page-select"
	import merge from "webpack-merge"
	import { beyouIm } from "../im"

	var userHistory = null

	export default {
		name: "detail",
		data() {
			return {
				hallInfo: {
					count: 0,
					hallIds: []
				},
				warnMessage: null,
				// type: "resultEmpty",
				type: null,
				// type: "normal",
				backShow: false,
				backLoading: false,
				hallImage: require("@assets/detail/ic_cinema_active.png"),
				warnImage: require("@assets/img_art_maintenance.png"),
				backgroundImage: require("@assets/index.png"),
				bigPlayImg: require("@assets/play_big.png"),
				avatarImage: require("@assets/avatar2.png"),
				wantIcon: require("@assets/explore/want.png"),
				timeIcon: require("@assets/aside/aside7.png"),
				emptyIcon: require("@assets/explore/empty.png"),
				selectIcon: require("@assets/recommond/select.png"),
				selectIcon2: require("@assets/recommond/select2.png"),
				thumb: require("@assets/thumb.png"),
				recIcon: require("@assets/home/rec.png"),

				goodIcon: require("@assets/detail/good.png"),
				nogoodIcon: require("@assets/detail/nogood.png"),
				goodDisabledIcon: require("@assets/detail/good-disabled.png"),
				nogoodDisabledIcon: require("@assets/detail/nogood-disabled.png"),
				buttonIcon1: require("@assets/detail/button1.png"),
				buttonIcon2: require("@assets/detail/button2.png"),
				buttonIcon3: require("@assets/detail/button3.png"),
				titleIcon: require("@assets/detail/title.png"),
				noworkIcon: require("@assets/detail/empty.png"),
				moreIcon: require("@assets/detail/more.png"),
				more2Icon: require("@assets/detail/more2.png"),
				openIcon: require("@assets/detail/open.png"),
				closeIcon: require("@assets/detail/close.png"),
				searchWidth: 0,
				searchPlaceHolder: "复仇者联盟",
				searchVal: "",
				user: {
					name: "胖虎不是铁憨憨",
				},

				activeName: "1",
				moiveList: [],

				isPlay: false,
				player: null,
				detail: {
					tv: {
						playInfo: [
							{
								episode: [],
							},
						],
					},
					moive: {
						playUrl: "",
					},
					directors: [],
					actors: [],
					intro: "",
					rating: 0,
					tags: [],
				},

				currentPage: 1,
				pageSize: 20,
				commentType: "1",

				isAuto: true,
				recList: [],

				isInPlayList: false,

				commentValue: "",
				pagination: {
					currentPage: 1,
					pageSize: 0,
					numFound: 0,
					totalPage: 0,
				},
				commentList: [],
				loading: false,

				backValue: "1",
				dp: null,
				im: null,
				backList: ["画质低", "分辨率错误", "音画不同步", "无字幕", "字幕不同步", "字幕翻译差", "黑屏", "播放卡顿"],
				tvHeight: 0,
				introShow: false,
				introFinish: false,
				introShowButton: true,
				firstLoading: false,

				videoStyle: {},
				endShow: false,
				commentLength: 0,
				userMore: 0,
				backVideoStyle: {},
				commentMaintain: false,
				commentMaintainMsg: ''
			}
		},
		watch: {
			commentType(newVal, oldVal) {
				this.getComment()
			},
		},
		computed: {
			isLogin() {
				console.log(this.$store.state.userData)
				return this.$store.state.userData && this.$store.state.userData.token
			},
			userData() {
				return this.$store.state.userData
			},
		},
    beforeRouteUpdate(to, form, next) {
      console.log(to, form, next)
      if(to.path === form.path) {
        if (to.query.id !== form.query.id) {
          this.videoId = to.query.id
          this.init()
        }
      }
      next()
    },
		methods: {
			clickMore(e, id) {
				console.log(e)
				if (e) this.userMore = id
				else this.userMore = 0
			},
			async createHall() {
				this.createHallNext(this.detail.title)
			},
			async createHallNext(name) {
				let httpObj = {
					name,
					videoId: this.detail.videoId,
					videoType: this.detail.type,
					type: "public",
				}
				if (this.detail.type == "tv")
					httpObj.episodeId = this.detail.tvSelected
						? this.detail.tvSelected
						: this.detail.tv.playInfo[0].episode[0].episodeId

				const result = await this.api.createHall(httpObj)
				console.log('createHall', result)
				if (result.code === 1) {
				  const {roomName,accountId,seatLimit,needRequest,coverUrl} = result.data
					this.im = new beyouIm({
						groupId: result.data.roomId,
						userID: result.data.accountId,
						userSig: result.data.userSig,
						avatar: this.getImg(this.userData.userInfo.avatar, 60, 60),
						nick: this.userData.userInfo.nickname,
            roomInfo: JSON.stringify({
              roomName: roomName,
              memberCount: '1',
              ownerId: accountId,
              seatSize: seatLimit.open,
              needRequest: needRequest,
              cover: coverUrl
            }),
            playInfo: JSON.stringify(result.data.playInfo),
					})

          console.log('创建影厅 播放信息', result.data.playInfo)

          // "{\"roomName\":\"半世界\",
          // \"memberCount\":0,\
          // "ownerId\":\"user_29\",
          // \"seatSize\":8,
          // \"needRequest\":0,
          // \"ownerName\":\"LG\",
          // \"cover\":\"\\/
          // pic1\\/5cbe9e5312704b12a1ee553a52e863c7.jpg\"}"
					const createResult = await this.im.createGroup(name)
					console.log("createResult", createResult)
					if (createResult.code === 0) {
						const callback = await this.api.hallRoomCallBackStatus(result.data.hallId)

						if (result.code === 1) {
							this.$message.success("创建成功")
							await this.im.tim.logout()
							setTimeout(() => {
								this.$router.push({
									name: "hall-detail",
									query: {
										id: this.videoId,
										hallId: result.data.hallId,
									},
								})
							}, 500)
						}
					}
				} else {
					this.$message.error(result.msg)
					console.log(result.data.msg)
				}

				console.log(result)
			},
			goLocalDetail(id) {
				console.log(id)
				// this.$router.push({
				// 	query: merge(this.$route.query, { id }),
				// })

				// location.href = '/detail?id=' + id
        console.log( this.$router)

        this.$router.push({name:'detail',query: {id:id}})

      },
			async showBack() {
				this.backShow = true

				const result = await this.api.getFeedBack()

				console.log(result)
			},
			async submitBack(item) {
				this.backLoading = true
				const result = await this.api.addFeedBack({
					videoId: this.detail.videoId,
					type: this.detail.type,
					problem: this.backValue,
				})

				if (result.code === 1) {
					this.$message.success("反馈成功")
					this.backShow = false
				} else {
					this.$message.error(result.msg)
				}
				this.backLoading = false
			},

			clickTvTitle(index) {
				console.log(index)
				this.detail.tv.playInfo[index].isShow = !this.detail.tv.playInfo[index].isShow
				this.$forceUpdate()
			},
			selectTv(child, index) {
				this.detail.tvSelected = child.episodeId
				this.goPlay(child.playUrl)
				this.$forceUpdate()
				console.log(child)
			},
			async init() {
				this.loading = true
				const result = await this.api.getMoiveDetail({
					id: this.videoId,
				})

				console.log(result)
				if(result.code === 0) {
					this.warnMessage = result.msg
					this.loading = false
					return
				}
				this.hallInfo = result.data.hallInfo
				this.detail = result.data
				this.type = result.data.type
				if (this.type == "tv") {
					if (this.detail.tv && this.detail.tv.playInfo) {
						this.detail.tvLength = 0

						for (let item of this.detail.tv.playInfo) {
							this.detail.tvLength = this.detail.tvLength + Array.isArray(item.episode) && item.episode.length
							item.isShow = false
						}
						this.detail.tv.playInfo[0].isShow = true
						this.detail.tvSelected = this.detail.tv.playInfo[0].episode[0].episodeId
					}
				}

				const rec = await this.api.getRecMoive({
					pageSize: 20,
				})

				await this.checkPlayList()
				await this.getComment()
				this.getVideoStyle()
				this.recList = rec.data.list
				console.log(this.detail)
				this.loading = false
			},

			async getComment() {
				const comment = await this.api.getComment({
					currentPage: this.pagination.currentPage,
					type: this.commentType,
					videoId: this.detail.videoId,
				})
				console.log(comment.code)
				// 评论维护中
				if(comment.code === 31) {
					this.commentMaintain = true
					this.commentMaintainMsg= comment.msg
				} else {
					let commentLength = 0
					this.commentHotList = this.commentList = comment.data.list.map((i) => {
						commentLength += 1
						i.isShowSubmit = false
						i.submitValue = ""
						if (i.comment.children && i.comment.children.length) {
							i.comment.children = i.comment.children.map((child) => {
								child.isShowSubmit = false
								child.submitValue = ""
								return child
							})
							i.comment.currentPage = 1
							i.comment.start = 0
							i.comment.end = 10
							commentLength += 1
							i.comment.hotChildren = i.comment.children
								.map((child) => {
									child.isShowSubmit = false
									child.submitValue = ""
									return child
								})
								.sort((a, b) => b.comment.likeNum - a.comment.likeNum)
								.slice(0, 3)
							i.comment.showChildren = false
						}
						return i
					})

					// this.commentHotList =  [...this.commentList].sort((a,b) => b.comment.likeNum - a.comment.likeNum)
					// console.log(comm)
					this.commentLength = comment.data.count
					this.pagination = comment.data.pagination
					console.log(this.commentList)
				}
			},
			pageChange(e) {
				this.pagination.currentPage = e
				this.getComment()
				console.log(e)
			},

			handleClick(tab, event) {
				console.log(tab, event)
			},
			async goPlay(url) {
				console.log(this.type, this.detail, url)
				console.log(this.userData === null)
				if(this.userData.token === "" && this.userData.token.length === 0) {
					this.$store.commit('resetUserData')
					this.$router.push({
						name: 'login',
						params: {
							code :-1
						}
					})
					return
				}
				/**
				 * 有三种进来的逻辑
				 * 1 电影
				 * 	（1） 没有播放记录，直接开始播放
				 *      （2） 有播放记录，播放后跳转
				 * 2 电视剧
				 * 	（1） 没有播放记录，默认选中第一集，然后开始播放
				 * 	（2） 有播放记录，播放记录中的集数，然后跳转
				 */

				if (this.type === "movie") {
					const quality = await this.getQuality(this.detail.movie.playUrl)
					const video = {
						defaultQuality: 0,
						quality,
						type: "auto",
						url: quality[0].url,
					}
					await this.switchOrCreateVideo(video)
					this.isPlay = true

					setTimeout(() => {
						this.getSecondPlay()
					}, 1000)
					console.log(quality)
				} else if (this.type === "tv") {
					if (url && typeof url === "string") {
						const quality = await this.getQuality(url)
						const video = {
							defaultQuality: 0,
							quality,
							type: "auto",
							url: quality[0].url,
						}
						await this.switchOrCreateVideo(video)
						this.isPlay = true

						// setTimeout(() => {
						// 	this.getSecondPlay()
						// }, 1000)
					} else if (this.detail.tv.playRecord && this.detail.tv.playRecord.second) {
						const { seasonId, episodeId } = this.detail.tv.playRecord
						const currentSeason = this.detail.tv.playInfo.find((i) => i.seasonId === seasonId)
						const currentItem = currentSeason.episode.find((i) => i.episodeId === episodeId)

						this.detail.tvSelected = currentItem.episodeId

						const quality = await this.getQuality(currentItem.playUrl)
						const video = {
							defaultQuality: 0,
							quality,
							type: "auto",
							url: quality[0].url,
						}
						await this.switchOrCreateVideo(video)
						this.isPlay = true

						setTimeout(() => {
							this.getSecondPlay()
						}, 1000)
					} else {
						this.detail.tvSelected = this.detail.tv.playInfo[0].episode[0].episodeId

						const quality = await this.getQuality(this.detail.tv.playInfo[0].episode[0].playUrl)
						const video = {
							defaultQuality: 0,
							quality,
							type: "auto",
							url: quality[0].url,
						}
						await this.switchOrCreateVideo(video)
						this.isPlay = true

						setTimeout(() => {
							this.getSecondPlay()
						}, 1000)
					}
				}
			},

			async switchOrCreateVideo(video) {

				let _this = this
				if (this.dp) {
					this.dp.switchVideo(video)
					this.dp.play()
				} else {
					console.log('video' , video)
					this.dp = new DPlayer({
						container: document.getElementById("dplayer"),
						autoplay: true,
						video,
					})
					this.dp.on("ended", function() {
						console.log("结束了")
						if (_this.type === "movie") {
							_this.endShow = true
						} else {
							if (!_this.isAuto) return
							let seasonIndex = null
							let episodeIndex = null
							let season = null
							let item = null
							for (let _season of _this.detail.tv.playInfo) {
								for (let _item of _season.episode) {
									if (_item.episodeId === _this.detail.tvSelected) {
										seasonIndex = _this.detail.tv.playInfo
											.map((i) => i.seasonId)
											.indexOf(_season.seasonId)
										episodeIndex = _season.episode.map((i) => i.episodeId).indexOf(_item.episodeId)
										item = _item
										season = _season
									}
								}
							}

							console.log(season, item, seasonIndex, episodeIndex)
							if (episodeIndex !== season.episode.length - 1) {
								const next = season.episode[episodeIndex + 1]
								_this.detail.tvSelected = next.episodeId
								_this.goPlay(next.playUrl)
								_this.$forceUpdate()
							} else {
								if (seasonIndex === _this.detail.tv.playInfo.length - 1) {
									_this.endShow = true
								} else {
									const nextSeason = _this.detail.tv.playInfo[seasonIndex + 1]
									const next = nextSeason[0]
									_this.detail.tvSelected = next.episodeId
									_this.goPlay(next.playUrl)
									_this.$forceUpdate()
								}
							}
						}
					})
					// this.dp.on("ended", function() {

					// })
					if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
						console.log("我是safari浏览器")
						// this.dp.on("fullscreen", function() {
						// 	setTimeout(() => {
						// 		document.querySelector("#dplayer").style.width = "initial"
						// 		document.querySelector("#dplayer").style.height = "initial"
						// 	})
						// })
						// this.dp.on("fullscreen_cancel", function() {
						// 	// document.querySelector("#dplayer").style.width = _this.videoStyle.width
						// 	// document.querySelector("#dplayer").style.height = _this.videoStyle.height
						// })
					}
					// fullscreen
					// fullscreen_cancel
				}
			},

			getQuality(url) {
				let _this = this

				return new Promise((resolve, reject) => {
					try {
						let hls = new Hls()
						hls.loadSource(url)
						hls.on(Hls.Events.MANIFEST_PARSED, function(event, data) {
							const result = hls.levels.map((i,idx) => {

								return {
									url: i.url[0],
									name: idx===0?'480P':idx===1?'720P':'1080P',
									type: "hls",
								}
							})

							resolve(result.reverse())
						})
					} catch (e) {
						reject(e)
					}
				})
			},

			async getSecondPlay() {
				console.log("进来了")
				if (this.firstLoading) return
				if (this.type === "movie") {
					this.firstLoading = true
					const second = this.detail.movie.second
					if (second) {
						this.dp.seek(second)
						this.$message.success("已为您定位到上次播放进度。")
					}
				} else if (this.detail.tv.playRecord && this.detail.tv.playRecord.second) {
					this.firstLoading = true
					const { second, seasonId, seasonName, episodeId } = this.detail.tv.playRecord
					const currentSeason = this.detail.tv.playInfo.find((i) => i.seasonId === seasonId)
					const currentItem = currentSeason.episode.find((i) => i.episodeId === episodeId)

					this.$message.success("已为您定位到" + currentSeason.title + " " + currentItem.episodeName)
					this.dp.seek(second)
				}
			},

			async clickGood() {
				const result = await this.api.clickGood({
					videoId: this.detail.videoId,
				})
				if (result.code == 1) {
					this.$message.success(result.data.status ? "点赞成功" : "取消点赞成功")
					this.detail.giveUp = result.data.status
					this.detail.giveDown = 0
				} else {
					this.$message.warning(result.msg)
				}
			},
			async clickUnGood() {
				const result = await this.api.clickUnGood({
					videoId: this.detail.videoId,
				})
				if (result.code == 1) {
					this.$message.success(result.data.status ? "点踩成功" : "取消点踩成功")
					this.detail.giveDown = result.data.status
					this.detail.giveUp = 0
				} else {
					this.$message.warning(result.msg)
				}
			},
			async setPlayList() {
				const result = await this.api.setPlayList({
					videoId: this.detail.videoId,
				})
				console.log(result)
				if (result.code === 1) {
					this.$message.success(result.msg)
					this.isInPlayList = 1
					this.detail.videoLookList = [result.data]
				} else this.$message.error(result.msg)
			},
			async checkPlayList() {
				this.isInPlayList = this.detail.inPlaylist ? 1 : 0
			},
			async deletePlayList() {
				const lookIds = this.detail.videoLookList.filter((i) => i.videoId === this.detail.videoId).map((i) => i.id)

				const result = await this.api.deletePlayList({
					lookIds,
				})
				if (result.code === 1) {
					this.$message.success(result.msg)
					this.isInPlayList = 0
				}
				console.log(result)
			},
			async setComment() {
				if (!this.commentValue) {
					this.$message.warning("请填写评论后发表")
					return
				}
				let httpObj = {
					comment: this.commentValue,
					videoId: this.detail.videoId,
					type: 1,
				}
				const result = await this.api.setMoiveComment(httpObj)
				if (result.code === 1) {
					this.$message.success("评论成功")
					this.getComment()
					this.commentValue = ""
				} else {
					this.$message.error(result.msg)
				}
				console.log(result)
			},
			async submitByUser(item) {
				if (!item.submitValue) {
					this.$message.warning("请填写评论后回复")
					return
				}
				console.log(item)
				const httpObj = {
					videoId: this.detail.videoId,
					comment: item.submitValue,
					commentId: item.comment.commentId,
					type: 1,
				}
				const result = await this.api.setMoiveComment(httpObj)
				if (result.code === 1) {
					this.$message.success("评论成功")
					this.getComment()
					item.submitValue = ""
					item.isShowSubmit = false
				} else {
					this.$message.error(result.msg)
				}
			},
			async submitByComment(item, parent) {
				if (!item.submitValue) {
					this.$message.warning("请填写评论后回复")
					return
				}
				console.log(item)
				const httpObj = {
					videoId: this.detail.videoId,
					comment: item.submitValue,
					commentId: parent.comment.commentId,
					toUserId: item.userInfo.userId,
					type: 2,
				}
				const result = await this.api.setMoiveComment(httpObj)
				if (result.code === 1) {
					this.$message.success("评论成功")
					this.getComment()
					item.submitValue = ""
					item.isShowSubmit = false
				} else {
					this.$message.error(result.msg)
				}
			},

			async goodComment(item) {
				const result = await this.api.goodComment({
					commentId: item.commentId,
				})
				if (result.code === 1) {
					this.$message.success("点赞成功")
					item.liked = result.data.status
					this.getComment()
				} else {
					this.$message.error(result.msg)
				}
			},
			async setCommentTop({ commentId }) {
				console.log(commentId)
				const result = await this.api.setTopComment({
					commentId,
				})
				if (result.code === 1) {
					this.$message.success("置顶成功")
					this.getComment()
				} else {
					this.$message.error(result.msg)
				}
			},
			async deleteComment({ commentId }) {
				console.log(commentId)
				const result = await this.api.deleteComment({
					commentId,
				})
				if (result.code === 1) {
					this.$message.success("删除成功")
					this.getComment()
				} else {
					this.$message.error(result.msg)
				}
			},
			share() {
				this.$message.success("已复制链接，请分享给您的好友吧！")
				let container = this.$refs.shareButton
				let url = location.protocol + "//" + location.host + "/detail?id=" + this.videoId
				this.$copyText(url, container)
			},
			setHistory() {
				if (userHistory) clearInterval(userHistory)
				var userHistory = setInterval(() => {
					const isNeed = this.dp && !this.dp.video.paused ? true : false
					if (isNeed) {
						const httpObj = {
							videoId: this.detail.videoId,
							second: Math.ceil(this.dp && this.dp.video.currentTime),
						}
						if (this.type == "tv") {
							for (let item of this.detail.tv.playInfo) {
								for (let child of item.episode) {
									if (child.episodeId === this.detail.tvSelected) {
										httpObj.seasonId = item.seasonId
										httpObj.episodeId = child.episodeId
									}
								}
							}
						}

						this.api.setUserHistory(httpObj)
					}
				}, 1000)
			},
			introSwitch() {
				this.introShow = !this.introShow
			},
			getVideoStyle() {
				let boxWidth = document.body.offsetWidth - (document.body.offsetWidth > 1680 ? 320 : 240) - 100
				let width = this.type === "tv" ? boxWidth - 400 : boxWidth
				let height = this.type == "tv" ? Math.ceil(width * 0.61) : Math.ceil(width * 0.45)
				this.videoStyle = {
					width: width + "px",
					height: height + "px",
				}
				console.log(this.videoStyle)
			},
			goHall() {
				this.$router.push({
					name: "hall-detail",
					query: {
						id: this.detail.videoId,
						hallId: this.hallInfo.hallIds[0],
					},
				})
			}
		},
		destroyed() {
			if (userHistory) clearInterval(userHistory)
			if (this.im) this.im.tim.logout()
		},
		mounted() {
      console.log("this.mounted()")
      this.videoId = this.$route.query.id
      this.init()
      this.searchWidth = (document.body.offsetWidth - 320) * 0.68 + "px"
      this.tvHeight = (
          (document.body.offsetWidth - (document.body.offsetWidth > 1680 ? 320 : 240) - 100 - 400) *
          0.61
      ).toFixed()

      this.setHistory()
		},
		updated() {
			let _this = this
			this.$nextTick(() => {
				console.log(this.$refs.intro.offsetHeight)
				const height = this.$refs.intro.offsetHeight
				if (height && !this.introFinish) {
					this.introFinish = true
					if (height > 54) {
						// this.$refs.intro.classList.add('lineThree')
						_this.introShow = true
					} else if (height < 54) {
						this.introShowButton = false
					}
				}
			})
		},
		components: {
			searchBar,
			pageSelect,
		},
	}
</script>

<style lang="scss">
	.detail-movie {
		background: rgb(19, 21, 48);
		width: calc(100% - 100px);
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;

		padding: 0 50px;
		.detail-img {
			margin-top: 30px;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			.detail-img-right {
				width: 400px;
				flex-shrink: 0;
				height: 100%;
				background: #121330;
				overflow-y: auto;
				.tv-box {
					padding: 20px;
					.tv-box-title {
						font-size: 18px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 25px;
						color: #ffffff;
					}
					.tv-item-count {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-top: 10px;
						margin-bottom: 40px;
						.tv-item-count-left {
							font-size: 12px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 17px;
							color: rgba(121, 122, 142, 0.8);
						}
						.tv-item-count-right {
							span {
								font-size: 12px;
								font-family: PingFang SC;
								font-weight: 400;
								line-height: 17px;
								color: #ffffff;
								margin-right: 10px;
							}
						}
					}
					.tv-item {
						background: rgba(255, 255, 255, 0.03);
						border-radius: 5px;
						margin-bottom: 10px;

						.tv-item-title {
							height: 60px;
							width: calc(100% - 20px);
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 0 10px;
							cursor: pointer;
							.tv-item-title-left {
								.tv-item-title-1 {
									font-size: 16px;
									font-family: PingFang SC;
									font-weight: 500;
									line-height: 22px;
									color: rgba(182, 183, 195, 1);
								}
								.tv-item-title-2 {
									font-size: 13px;
									font-family: PingFang SC;
									font-weight: 500;
									line-height: 18px;
									color: rgba(121, 122, 142, 1);
									margin-left: 10px;
								}
							}
							.tv-item-title-right {
								width: 20px;
								height: 20px;
								cursor: pointer;
								img {
									width: 100%;
									height: 100%;
								}
							}
						}
						.tv-item-detail {
							display: flex;
							flex-wrap: wrap;
							justify-content: flex-start;
							align-items: flex-start;
							padding-bottom: 10px;
							.tv-item-detail-item {
								width: 40px;
								height: 40px;
								/* margin-right: 35px; */
								font-size: 16px;
								font-family: PingFang SC;
								font-weight: 600;
								line-height: 22px;
								color: #797a8e;
								text-align: center;
								line-height: 40px;
								margin: 0 16px;
								cursor: pointer;
							}
							.tv-item-detail-item-selected {
								background: #ff3465;
								opacity: 1;
								border-radius: 12px;
								color: white;
							}
						}
					}
				}
			}
			.detail-img-left {
				position: relative;
				width: 100%;
				.background {
					width: 100%;
				}
				.play {
					width: 270px;
					height: 270px;
					position: absolute;
					top: 0;
					display: block;
					right: 0;
					left: 0;
					bottom: 0;
					margin: auto;
					cursor: pointer;
				}
			}
		}
		.movie-detail {
			margin-top: 40px;
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			.moive-detail-left {
				width: 70%;
				.moive-detail-content {
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					.moive-detail-left-left {
						display: flex;
						justify-content: space-between;
						align-items: center;
						flex-direction: column;
						.moive-detiil-left-left-thmub {
							height: 230px;
							width: 160px;
						}
						.moive-detail-left-left-button {
							display: flex;
							justify-content: space-around;
							align-items: center;
							margin-top: 10px;
							width: 100%;
							img {
								width: 40px;
								height: 40px;
								cursor: pointer;
							}
						}
					}
					.moive-detail-left-right {
						width: 100%;
						margin-left: 20px;
						.moive-detail-left-right-title {
							font-size: 32px;
							font-family: PingFang SC;
							font-weight: 600;
							line-height: 45px;
							color: #b6b7c3;
							display: flex;
							justify-content: space-between;
							align-items: flex-start;
							.moive-detail-left-right-title-rating {
								font-size: 24px;
								font-family: DINPro;
								font-weight: 500;
								line-height: 31px;
								color: #797a8e;
								span {
									font-size: 40px;
									font-family: DINPro;
									font-weight: bold;
									line-height: 51px;
									color: #ff3465;
								}
							}
						}
						.moive-detail-left-right-tags {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							margin-top: 12px;
							div {
								background: rgba(255, 52, 101, 0.05);
								padding: 3px 10px;

								font-size: 13px;
								font-family: PingFang SC;
								font-weight: 500;
								line-height: 18px;
								color: #ff3465;
								margin-right: 10px;
								border-radius: 5px;
							}
						}
						.moive-detail-left-right-text {
							display: flex;
							justify-content: flex-start;
							align-items: flex-start;
							margin-top: 20px;

							label {
								font-size: 15px;
								font-family: PingFang SC;
								font-weight: 400;
								line-height: 21px;
								color: rgba(121, 122, 142, 0.5);

								margin-right: 12px;
								flex-shrink: 0;
							}
							div {
								font-size: 15px;
								font-family: PingFang SC;
								font-weight: 500;
								line-height: 21px;
								color: #797a8e;
							}
							.moive-detail-left-right-text-content {
								display: flex;
								justify-content: flex-start;
								align-items: flex-start;
								flex-wrap: wrap;
								line-height: 18px;
							}
						}
					}
				}

				.moive-detail-comment {
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					margin-top: 60px;
					.moive-detail-comment-avatar {
						width: 60px;
						height: 60px;
						overflow: hidden;
						border-radius: 50%;
						img {
							width: 100%;
							height: 100%;
						}
					}
					.moive-detail-comment-text {
						width: calc(100% - 85px);
						margin-left: 25px;
						.moive-detail-comment-button {
							display: flex;
							justify-content: space-between;
							align-items: center;
							margin-top: 10px;
							.moive-detail-comment-button-text {
								font-size: 16px;
								font-family: PingFang SC;
								font-weight: 400;
								line-height: 22px;
								color: #797a8e;
							}
							.moive-detail-comment-button-button {
								width: 100px;
								height: 45px;
								background: #ff3465;
								opacity: 1;
								border-radius: 10px;

								font-size: 16px;
								font-family: PingFang SC;
								font-weight: 400;
								line-height: 22px;
								color: #ffffff;
								display: flex;
								justify-content: center;
								align-items: center;
								cursor: pointer;
							}
						}
					}
				}
				.moive-detail-comment-list {
					margin-top: 30px;

					width: 100%;
					.moive-detail-comment-list-title {
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 100%;
						img {
							width: 20px;
							height: 20px;
						}
						span {
							font-size: 16px;
							font-family: PingFang SC;
							font-weight: 600;
							line-height: 25px;
							color: #797a8e;

							user-select: none;
						}
						.moive-detail-comment-list-title-left {
							display: flex;
							justify-content: center;
							align-items: center;
						}
						.moive-detail-comment-list-title-right {
							display: flex;
							justify-content: center;
							align-items: center;
							span {
								cursor: pointer;
							}
						}
					}
					.no-work {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						margin-bottom: 200px;
						img {
							width: 300px;
							height: 210px;
						}
						span {
							font-size: 15px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 21px;
							color: #797a8e;
							margin-top: 20px;
							user-select: none;
						}
					}
					.moive-detail-comment-list-content {
						margin-top: 20px;
						.moive-detail-comment-list-content-item {
							margin-bottom: 20px;
							display: flex;
							justify-content: center;
							align-items: flex-start;
							.moive-detail-comment-list-content-item-avatar {
								width: 60px;
								height: 60px;
								overflow: hidden;
								border-radius: 50%;
								border: 1px solid white;
								img {
									width: 100%;
									height: 100%;
								}
							}
						}
						.moive-detail-comment-list-content-item-content {
							width: 90%;
							margin-left: 20px;
							.moive-detail-comment-list-content-item-content-1 {
								display: flex;
								justify-content: flex-start;
								align-items: center;
								.moive-detail-comment-list-content-item-content-1-name {
									font-size: 14px;
									font-family: PingFang SC;
									font-weight: 400;
									line-height: 26px;
									color: #ffffff;
									margin-right: 20px;
								}
								.moive-detail-comment-list-content-item-content-1-top {
									font-size: 13px;
									font-family: PingFang SC;
									font-weight: 500;
									border: 1px solid #ff3465;
									opacity: 1;
									border-radius: 4px;
									color: #ff3465;
									margin-right: 10px;
									padding: 0 4px;
								}
								.moive-detail-comment-list-content-item-content-1-date {
									font-size: 13px;
									font-family: PingFang SC;
									font-weight: 400;
									line-height: 26px;
									color: #797a8e;
								}
							}
							.moive-detail-comment-list-content-item-content-2 {
								font-size: 14px;
								font-family: PingFang SC;
								font-weight: 400;
								line-height: 26px;
								color: #797a8e;
								margin-top: 14px;
								width: 100%;
								white-space: normal;
								word-break: break-all;
								word-wrap: break-word;
							}
							.moive-detail-comment-list-content-item-content-3 {
								display: flex;
								justify-content: space-between;
								align-items: center;
								.moive-detail-comment-list-content-item-content-3-left {
									display: flex;
									justify-content: flex-start;
									align-items: center;
									margin-top: 10px;
									.moive-detail-comment-list-content-item-content-3-left-good {
										display: flex;
										justify-content: center;
										align-items: center;
										img {
											width: 18px;
											height: 18px;
											margin-right: 5px;
										}
										span {
											font-size: 13px;
											font-family: PingFang SC;
											font-weight: 400;
											line-height: 26px;
											color: #797a8e;
										}
									}
									.moive-detail-comment-list-content-item-content-3-left-good:hover {
										cursor: pointer;
										span {
											color: #ff3465;
										}
									}
									.moive-detail-comment-list-content-item-content-3-left-submit {
										margin-left: 20px;
										font-size: 13px;
										font-family: PingFang SC;
										font-weight: 400;
										line-height: 26px;
										color: #797a8e;
									}
									.moive-detail-comment-list-content-item-content-3-left-submit:hover {
										cursor: pointer;
										color: #ff3465;
										text-decoration: underline;
									}
								}
								.moive-detail-comment-list-content-item-content-3-right {
									img {
										width: 18px;
										height: 18px;
									}
								}
							}
							.moive-detail-comment-list-content-item-content-4 {
								background: rgba(0, 0, 0, 0.2);
								padding: 10px;
								display: flex;
								justify-content: center;
								align-items: flex-end;

								border-radius: 10px;
								.moive-detail-comment-list-content-item-content-4-button {
									width: 70px;
									height: 35px;
									background: #ff3465;
									opacity: 1;
									border-radius: 10px;

									font-size: 16px;
									font-family: PingFang SC;
									font-weight: 400;
									line-height: 22px;
									color: #ffffff;
									display: flex;
									justify-content: center;
									align-items: center;
									cursor: pointer;
									margin-left: 4px;
								}
							}
							.moive-detail-comment-list-content-item-content-5 {
								background: rgba(0, 0, 0, 0.2);
								border-radius: 10px;
								padding: 10px;
								margin-top: 10px;
								.moive-detail-comment-list-content-item-content-5-item {
									margin-bottom: 10px;
									.moive-detail-comment-list-content-item-content-5-item-data {
										display: flex;
										justify-content: flex-start;
										align-items: flex-start;
										.moive-detail-comment-list-content-item-content-5-item-data-formname {
											font-size: 13px;
											font-family: PingFang SC;
											font-weight: 400;
											line-height: 26px;
											color: rgba(121, 122, 142, 0.5);
											flex-shrink: 0;
										}
										.moive-detail-comment-list-content-item-content-5-item-data-text {
											font-size: 13px;
											font-family: PingFang SC;
											font-weight: 400;
											line-height: 26px;
											color: #797a8e;
										}
									}
									.moive-detail-comment-list-content-item-content-5-item-button {
										display: flex;
										justify-content: space-between;
										align-items: center;
										.moive-detail-comment-list-content-item-content-5-item-button-left {
											display: flex;
											justify-content: flex-start;
											align-items: flex-start;
											.moive-detail-comment-list-content-item-content-5-item-button-date {
												font-size: 12px;
												font-family: PingFang SC;
												font-weight: 400;
												line-height: 26px;
												color: rgba(121, 122, 142, 0.5);

												margin-right: 20px;
											}
											.moive-detail-comment-list-content-item-content-5-item-button-good {
												font-size: 12px;
												font-family: PingFang SC;
												font-weight: 400;
												line-height: 26px;
												color: rgba(121, 122, 142, 0.5);

												margin-right: 10px;
											}
											.moive-detail-comment-list-content-item-content-5-item-button-good:hover {
												cursor: pointer;
												text-decoration: underline;
												color: #ff3465;
											}
											.moive-detail-comment-list-content-item-content-5-item-button-button {
												font-size: 12px;
												font-family: PingFang SC;
												font-weight: 400;
												line-height: 26px;
												color: rgba(121, 122, 142, 0.5);
											}
											.moive-detail-comment-list-content-item-content-5-item-button-button:hover {
												cursor: pointer;
												text-decoration: underline;
												color: #ff3465;
											}
										}
										.moive-detail-comment-list-content-item-content-5-item-button-right {
											img {
												width: 18px;
												height: 18px;
											}
										}
									}
								}
								.moive-detail-comment-list-content-item-content-5-item:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
			.moive-detail-right {
				width: 30%;
				.moive-detail-right-button {
					width: 100%;
					display: flex;
					justify-content: space-evenly;
					align-items: center;
					div {
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						cursor: pointer;
						img {
							width: 40px;
							height: 40px;
						}
						span {
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 20px;
							color: #797a8e;
						}
					}
				}
				.moive-detail-right-rec {
					padding: 20px;
					.moive-detail-right-rec-title {
						margin-bottom: 20px;
						img {
							width: 20px;
							height: 20px;
						}
						span {
							margin-left: 10px;

							font-size: 18px;
							font-family: PingFang SC;
							font-weight: 600;
							line-height: 25px;
							color: #797a8e;
						}
					}
					.moive-detail-right-rec-item {
						width: 100%;
						margin-bottom: 30px;
						position: relative;
						border-radius: 10px;
						overflow: hidden;

						.moive-detail-right-rec-item-img {
							width: 100%;
							img {
								width: 100%;
								cursor: pointer;
							}
						}
						.moive-detail-right-rec-item-text {
							position: absolute;
							bottom: 0;
							left: 0;
							width: calc(100% - 40px);
							height: 50%;

							background: linear-gradient(
									180deg,
									rgba(0, 0, 0, 0) 0%,
									rgba(0, 0, 0, 0.6) 48%,
									rgba(0, 0, 0, 0.85) 100%
							);
							padding-left: 20px;
							opacity: 1;
							border-radius: 0px 0px 10px 10px;
							display: flex;
							flex-direction: column-reverse;
							align-items: flex-start;
							padding-bottom: 10px;
							padding-right: 20px;
							.moive-detail-right-rec-item-text-title {
								font-size: 18px;
								font-family: PingFang SC;
								font-weight: 600;
								line-height: 25px;
								color: #ffffff;
								text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
								padding-left: 4px;
							}
							.moive-detail-right-rec-item-text-title:hover {
								cursor: pointer;
								text-decoration: underline;
							}
							.moive-detail-right-rec-item-text-tags {
								span {
									font-size: 14px;
									font-family: PingFang SC;
									font-weight: 500;
									line-height: 20px;
									color: #ffffff;
									text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
								}
								.moive-detail-right-rec-item-text-tags-main {
									padding: 0 4px;
								}
							}
							.moive-detail-right-rec-item-text-tags-main:hover {
								cursor: pointer;
								text-decoration: underline;
								padding-right: 4px;
							}
						}
					}
				}
			}
		}
	}

	.moive-detail-comment,
	.moive-detail-comment-list-content-item-content-4 {
		.el-textarea {
		}
		.el-input__inner,
		.el-textarea__inner {
			background-color: rgba(121, 122, 142, 0.1);
			color: #b6b7c3;
			border: none !important;
		}
		.el-form-item__label {
			font-size: 15px;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(121, 122, 142, 1);
		}
	}

	.back-box-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		.back-box {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: wrap;
			.back-box-item {
				margin: 0 10px;
				width: 208px;
				background: #f8f8f8;
				border: 1px solid #eeeeee;
				opacity: 1;
				border-radius: 5px;
				margin-bottom: 20px;
				height: 40px;
				font-size: 15px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 26px;
				color: #121330;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
			.back-box-item-selected {
				background: #ffd6e0;
				border: 1px solid #ff3465;
				opacity: 1;
				border-radius: 5px;
			}
		}

		.back-box-button {
			width: 460px;
			height: 60px;
			background: #ff3465;
			opacity: 1;
			border-radius: 5px;
			margin-top: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 22px;
			color: #ffffff;
			cursor: pointer;
			user-select: none;
		}
	}
	.create-button {
		width: 91px;
		height: 30px;
		background: #ff3465;
		opacity: 1;
		border-radius: 5px;
		display: inline-block;
		font-size: 11px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 16px;
		color: #ffffff;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 12px;
		cursor: pointer;
	}

	@media screen and (max-width: 1680px) {
		.detail-movie {
			.movie-detail {
				.moive-detail-left {
					.moive-detail-content {
						.moive-detail-left-right {
							.moive-detail-left-right-title {
								font-size: 24px !important;
								.moive-detail-left-right-title-rating {
									font-size: 20px !important;
									span {
										font-size: 32px !important;
									}
								}
							}
							.moive-detail-left-right-text {
								div,
								label {
									font-size: 13px !important;
								}
							}
						}
					}
				}
				.moive-detail-right {
					.moive-detail-right-rec {
						.moive-detail-right-rec-item {
							.moive-detail-right-rec-item-text {
								.moive-detail-right-rec-item-text-title {
									font-size: 14px !important;
								}
								.moive-detail-right-rec-item-text-tags {
									span {
										font-size: 12px !important;
									}
								}
							}
						}
					}
				}
			}
		}
		.intro-button {
			font-size: 12px;
		}
	}

	.lineThree {
		height: 54px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.intro-button {
		float: right;
		margin-right: 12px;
		flex-shrink: 0;
		color: #ff3465;
		cursor: pointer;
	}
	.show-children-button {
		color: #797a8e;
		font-size: 13px;
	}
	.show-children-button:hover {
		color: #ff3465;
		cursor: pointer;
		text-decoration: underline;
	}
	.show-children-line {
		height: 1px;
		/* width: 100%; */
		background: #797a8e36;
		/* padding: 0 20px; */
		margin-bottom: 12px;
		width: calc(100% - 40px);
		margin-left: 20px;
	}

	.join-hall {
		width: 127px;
		height: 30px;
		line-height: 30px;
		background: #2A2B44;
		border-radius: 5px;
		line-height: 30px;
		color: #ffffff;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 12px;
		cursor: pointer;
	}
	.join-hall > span {
		display: inline-block;
		line-height: 25px;
		font-size: 11px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #FF3465;


	}

</style>
